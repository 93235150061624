



































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import lodash from "lodash";
import table from "@/utils/table";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableRowHeader from "vuetable-2/src/components/VuetableRowHeader.vue";
import VuetableFieldCheckbox from "vuetable-2/src/components/VuetableFieldCheckbox.vue";

import DetailRow from "@/components/Shared/Table/DetailRow.vue";
import RowFilter from "@/components/Shared/Table/RowFilter.vue";

import DetailField from "@/components/Shared/Table/DetailField.vue";
import StatusField from "@/components/Shared/Table/StatusField.vue";
import ActionBopAccueilField from "@/components/Shared/Table/ActionBopAccueilField.vue";
import ActionBopField from "@/components/Shared/Table/ActionBopField.vue";
import CategoryField from "@/components/Shared/Table/CategoryField.vue";
import EditField from "@/components/Shared/Table/EditField.vue";
import DropdownField from "@/components/Shared/Table/DropdownField.vue";
import CoeffOptiondropdownField from "@/components/Shared/Table/CoeffOptionDropdownField.vue";
import SwitchField from "@/components/Shared/Table/SwitchField.vue";
import SitesTypeTravauxField from "@/components/Shared/Table/SitesTypeTravauxField.vue";
import OperationTypeField from "@/components/Shared/Table/OperationTypeField.vue";
import OperationFamiliesField from "@/components/Shared/Table/OperationFamiliesField.vue";
import ComplementsCoutsSuppField from "@/components/Shared/Table/ComplementsCoutsSuppField.vue";
import { BIcon, BIconTrashFill, BIconFiles } from "bootstrap-vue";
import api from "@/utils/api";
import { Guid } from "guid-typescript";
import ModalService from "@/services/ModalService";
import File from "@/models/File";
import User from "@/models/User";
import store from "@/store";
import TableApiHelper from "@/utils/TableApiHelper";
import {debounce} from "@/utils/helpers";

Vue.component("my-detail-row", DetailRow);
Vue.component("vuetable-field-checkbox", VuetableFieldCheckbox);

type SortOrder = {
  field: string;
  direction: string;
  sortField: string;
};

@Component({
  components: {
    VuetablePagination,
    VuetableRowHeader,
    RowFilter,
    DetailField,
    StatusField,
    ActionBopAccueilField,
    ActionBopField,
    CategoryField,
    EditField,
    SwitchField,
    DropdownField,
    CoeffOptiondropdownField,
    SitesTypeTravauxField,
    OperationTypeField,
    OperationFamiliesField,
    BIconTrashFill,
    BIconFiles,
    ComplementsCoutsSuppField,
    // UnsavedModal
    Modal: () => import("@/components/Shared/Modal.vue"),
  },
})
export default class TableApi extends Vue {
  @Prop({ required: true }) public columns!: Array<Object>;
  @Prop() public title!: String;
  @Prop() public addLabel!: String;
  @Prop() public saveLabel!: String;
  @Prop() public importLabel!: String;
  @Prop() public exportLabel!: String;
  @Prop() public searchLabel!: String;
  @Prop() public bulkDeleteLabel!: String;
  @Prop() public bulkDuplicateLabel!: String;
  @Prop() public modifiedData!: Array<Object>;
  @Prop() readonly exportUrl!: string;
  @Prop({ required: true }) public url!: string;
  @Prop({ required: true }) public trackBy!: string;
  @Prop({ default: ()=> "" }) public bulkTrackBy!: string;
  @Prop() public searchBar!: Boolean;
  @Prop() public checkedRows!: Array<Object>;
  @Prop() readonly hideAdd!: Boolean;
  @Prop() readonly hideSave!: Boolean;
  @Prop() readonly notEditable!: Boolean;
  @Prop() readonly notAllowedExcel!: Boolean;
  @Prop() readonly saveAlwaysActive!: Boolean;
  @Prop() public isRowClickable!: Boolean;
  @Prop({ default: () => true }) readonly isButtonFooterVisible!: Boolean;
  @Prop({ default: () => 20 }) private readonly perPage!: number;


  private apiUrl:string = "";
  private selectedRows: Array<Object> = [];
  private bulkActionsSelectedRows: Array<string> = [];

  private tableKey:string = "";
  public updatedRows: Array<Object> = [];
  public initialRows: Array<Object> = [];
  private sortOrder: Array<Object> = [];
  private searchInput: string = "";
  private loading: Boolean = false;
  private selectedRow: Array<String> = [];
  private vue: any = this.$parent.$parent.$parent;
  private tableApiHelper:TableApiHelper  = new TableApiHelper(null,null);


  private get isRowClickTemporaryDisabled(): boolean {
    return this.$store.state.isRowClickTemporaryDisabled;
  }
  private set isRowClickTemporaryDisabled(value: boolean) {
    this.$store.commit("changeIsRowClickTemporaryDisabled", value);
  }
  private  get authorization(){
    const user:User = store.getters.user;
    return user.bearerToken ? 'Bearer '+user.bearerToken: 'Basic '+user.authenticationToken;
  }
  private get vueTable():any{
    return this.$refs.vuetable;
  }
  private debounceChecker:number|null = null;
  private bulkCheckedRowsHistory:Map<string,any> = new Map<string, any>();
  private paginationChanged:boolean = false;

  /**
   * ######
   * Methods
   * ######
   *
   */
  private rowClick(data: any) {
    if (this.isRowClickable) {
      if (!this.isRowClickTemporaryDisabled) {
        this.$emit("row-clicked", data);
      } else {
        this.isRowClickTemporaryDisabled = false;
      }
    }
  }

  private hideKeyboard() {
    (<HTMLInputElement>document.activeElement).blur();
  }

  private onLoading() {
    //console.log("Loading ...");
    this.loading = true;
  }

  private onLoaded() {
    //console.log("Loaded");
    this.loading = false;
    this.$emit("on-loaded");
  }

  public save(): void {
    this.$emit("save-data");
  }

  public add(): void {
    this.$store.commit("changeHasBeenModified", true);
    this.$emit("add-data");

  }
  private handleBulkDuplicate(){
    if(!this.bulkActionsSelectedRows.length)return;
    // on met à jour avec la liste actuelle (pour éviter les duplicate)
    this.updateBulkCheckedRowsHistory();
    const allCheckedTableData = Array.from(this.bulkCheckedRowsHistory.values());
    const data = this.tableApiHelper.getValuesFromTrackId(allCheckedTableData,this.bulkActionsSelectedRows,this.bulkTrackBy);
    this.$emit('handleBulkDuplicate', data);
  }
  private handleBulkDelete(){
    if(!this.bulkActionsSelectedRows.length)return;
    this.updateBulkCheckedRowsHistory();
    const allCheckedTableData = Array.from(this.bulkCheckedRowsHistory.values());
    this.$emit('handleBulkDelete',this.tableApiHelper.getValuesFromTrackId(allCheckedTableData,this.bulkActionsSelectedRows,this.bulkTrackBy))
  }
  private getRawData(props: any): any {
    const obj = props.rowData;
    const path = props.rowField.property;
    return table.findByPath(obj, path);
  }

  /**
   * Add new row to the table
   * @param newRow
   */
  public prependRow(newRow:any){
    this.updatedRows.unshift(newRow);
    this.vueTable.tableData.unshift(newRow);
    this.selectedRow.push(newRow[this.trackBy]);
  }
  public refreshAPI(soft:boolean=false): void {
    if(soft)
      this.tableKey = Guid.create().toString();
    this.onLoading();
    this.tableKey = Guid.create().toString();
    this.bulkActionsSelectedRows = [];
    this.$store.commit('changeHasBeenModified',false);
    this.onLoaded();
  }

  private download(props: any) {
    this.$emit("download-data", props);
  }



  public onPaginationData(paginationData: any): void {
    (<any>this.$refs.pagination).setPaginationData(paginationData);
  }
  private  updateBulkCheckedRowsHistory(){
    this.vueTable.tableData.forEach(row => {
      const key:string = table.findByPath(row,this.bulkTrackBy);
      this.bulkCheckedRowsHistory.set(key,row);
    });
  }
  private onChangePage(page: any): void {
    this.updateBulkCheckedRowsHistory();
    this.vueTable.changePage(page);
    this.paginationChanged = true;
  }
  private onVuetableLoaded(){
    if(!this.paginationChanged)return;

    this.$nextTick().then(()=> {
      this.$emit('pagination-change-page');
      this.paginationChanged = false;
    });
  }
  private getrawData(obj: any, path: string): any {
    return table.findByPath(obj, path);
  }


  private onRowClass(dataItem, index) {
    return this.selectedRow.includes(dataItem[this.trackBy])
      ? "selected-row"
      : "";
  }

  private async duplicate(params: any) {
    const rowData = params.rowData;
    const rowIndex = params.rowIndex + 1;
    const $vueTable = this.vueTable;

    const restOfArray = $vueTable.tableData.splice(rowIndex);
    let newData = lodash.cloneDeep(rowData);
    await this.$nextTick();

    table.setByPath(newData, this.trackBy, Guid.create().toString());
    $vueTable.tableData.push(newData);
    $vueTable.tableData = $vueTable.tableData.concat(restOfArray);

    this.selectedRow.push(newData[this.trackBy]);
    this.modify(newData);
    this.$emit("duplicate-data", newData);
    }

  private remove(props: any) {
    if (props.rowField.disableRemoveButton) return;
    this.$emit("remove-data", {
      rowData: props.rowData,
      rowIndex: props.rowIndex,
    });
    }

    private showModal(props: any) {
        this.$emit('open-anotation-modal', props);
    }

  public deleteRow(rowIndex) {
    this.refreshAPI();
  }
  /**
   * modify
   * Triggered when a cell has been modified
   */
  public modify(props: any) {
    const $vueTable = this.vueTable;
    const path = $vueTable.trackBy;
    this.$store.commit("changeHasBeenModified", true);
    const propToSearch = table.findByPath(props, path);

    let index = this.modifiedData.findIndex(function(item, index) {
      return propToSearch === table.findByPath(item, path);
    });
    if (index > -1) {
      this.modifiedData.splice(index, 1);
    }
    this.modifiedData.push(props);
  }
  public isBulkAllSelected(selectedRowsHash:Set<string> = new Set<string>()){

    this.bulkActionsSelectedRows.forEach((trackId:string) => selectedRowsHash.add(trackId));
    let counter = 0;
    this.vueTable.tableData.forEach(rowData => {
      const trackId:string = this.tableApiHelper?.findByPath(rowData,this.bulkTrackBy);
      if(selectedRowsHash.has(trackId)){
        counter++;
      }
    })
    return this.vueTable.tableData.length < this.perPage ? counter === this.vueTable.tableData.length : counter === this.perPage;
  }
  public bulkSelectAll(){
    const selectedRowsHash:Set<string> = new Set<string>();

    if(this.isBulkAllSelected(selectedRowsHash)){

      this.vueTable.tableData.forEach(rowData => {
        const trackId:string = this.tableApiHelper?.findByPath(rowData,this.bulkTrackBy);
        selectedRowsHash.delete(trackId);
      });
      this.bulkActionsSelectedRows = Array.from(selectedRowsHash.values());
      return;
    }
    this.vueTable.tableData.forEach(rowData => {
      const trackId:string = this.tableApiHelper?.findByPath(rowData,this.bulkTrackBy);
      if(!selectedRowsHash.has(trackId)) {
        this.bulkActionsSelectedRows.push(trackId);
      }
    })
  }
  private lastTimeStampExecution:number = 0;
  private static getCurrentSeconds():number{
    return Date.now();
  }
  /**
   * Update the rows based on a search value
   */

  private search(): void {
    this.debounceChecker = debounce(()=> {
      const filterKey = "&filter=";
      const explodedUrl = this.apiUrl.split(filterKey);
      this.apiUrl = `${explodedUrl[0]}${filterKey}${this.searchInput}`
    },this.debounceChecker);
  }

  /**
   * #######
   * Hooks
   * #######
   */
  private  created(){
    this.tableApiHelper = new TableApiHelper(this.$store, this.$api);
    this.bulkTrackBy??= this.trackBy;
    this.apiUrl = this.url;
  }
}
